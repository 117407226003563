<template>
  <div id="bg">
    <div id="login_wrap">
      <div id="login">
        <div class="form-box">
          <a-spin tip="Loading..." :spinning="loading">
            <a-form>
              <a-form-item has-feedback v-bind="validateInfos.Uname">
                <a-input class="a-input" v-model:value="modelRef.Uname">
                  <template #prefix>
                    <UserOutlined />
                  </template>
                </a-input>
              </a-form-item>

              <a-form-item has-feedback v-bind="validateInfos.Pwd">
                <a-input
                  class="a-input"
                  v-model:value="modelRef.Pwd"
                  type="password"
                >
                  <template #prefix>
                    <LockOutlined />
                  </template>
                </a-input>
              </a-form-item>

              <a-button block class="a-input" @click="onSubmit">管理登录</a-button>
            </a-form>
          </a-spin>
        </div>
      </div>

      <div id="login_img">
        <!-- 图片绘制框 -->
        <span class="circle">
          <span></span>
          <span></span>
        </span>
        <span class="star">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </span>
        <span class="fly_star">
          <span></span>
          <span></span>
        </span>
        <p id="title">HGStuPlatform</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, toRaw } from 'vue'
import { UserOutlined, LockOutlined } from '@ant-design/icons-vue'
import { Form } from 'ant-design-vue'
import form from '@/mixins/form'

const useForm = Form.useForm
export default defineComponent({
  components: {
    UserOutlined,
    LockOutlined
  },
  mixins: [form],
  setup () {
    const loading = ref(false)

    const modelRef = reactive({
      Uname: '',
      Pwd: ''
    })

    const rulesRef = reactive({
      Uname: [
        {
          required: true,
          message: '请输入用户名'
        }
      ],
      Pwd: [
        {
          required: true,
          message: '请输入密码'
        }
      ]
    })

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    )

    return {
      loading,
      modelRef,
      validateInfos,
      resetFields,
      validate
    }
  },

  mounted () {
    this.$nextTick(() => {
      window.onkeydown = e => {
        if (e.keyCode === 13) {
          this.onSubmit()
        }
      }
    })
  },

  unmounted () {
    window.onkeydown = () => true
  },

  methods: {
    onSubmit () {
      this.loading = true
      this.validate()
        .then(() => {
          this.$store
            .dispatch('SLogin', toRaw(this.modelRef))
            .then(() => {
              this.loading = false
              this.$message.success('登录成功')
              this.$store.commit('SET_PORT', 'finance')
              this.$router.push({ name: 'finance' })
            })
            .catch(e => {
              this.loading = false
            })
        })
        .catch(() => {
          this.loading = false
          this.$message.error('请正确输入账户信息')
        })
    }
  }
})
</script>

<style>
#bg {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#login_wrap {
  width: 980px;
  height: 400px;
  border-radius: 10px;
  font-family: "neo";
  overflow: hidden;
  box-shadow: 0px 0px 120px rgba(0, 0, 0, 0.25);
}
#login {
  width: 50%;
  height: 100%;
  min-height: 400px;
  background: linear-gradient(45deg, #9a444e, #e06267);
  position: relative;
  float: right;
}
#login #status {
  width: 90px;
  margin: 40px auto;
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  position: relative;
  overflow: hidden;
}
#login #status i {
  font-style: normal;
  position: absolute;
  transition: 0.5s;
}
.form-box {
  padding: 100px 70px;
  height: 400px;
}
.a-input {
  height: 50px;
}
#bg {
  background: linear-gradient(45deg, #211136, #bf5853);
  height: 100%;
}
/*绘图*/
#login_img {
  width: 50%;
  min-height: 400px;
  background: linear-gradient(45deg, #221334, #6c3049);
  float: left;
  position: relative;
}
#login_img span {
  position: absolute;
  display: block;
}
#login_img .circle {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: linear-gradient(45deg, #df5555, #ef907a);
  top: 70px;
  left: 50%;
  margin-left: -100px;
  overflow: hidden;
}
#login_img .circle span {
  width: 150px;
  height: 40px;
  border-radius: 50px;
  position: absolute;
}
#login_img .circle span:nth-child(1) {
  top: 30px;
  left: -38px;
  background: #c55c59;
}
#login_img .circle span:nth-child(2) {
  bottom: 20px;
  right: -35px;
  background: #934555;
}
#login_img .star span {
  background: radial-gradient(#fff 10%, #fff 20%, rgba(72, 34, 64, 0));
  border-radius: 50%;
  box-shadow: 0 0 7px #fff;
}
#login_img .star span:nth-child(1) {
  width: 15px;
  height: 15px;
  top: 50px;
  left: 30px;
}
#login_img .star span:nth-child(2) {
  width: 10px;
  height: 10px;
  left: 360px;
  top: 80px;
}
#login_img .star span:nth-child(3) {
  width: 5px;
  height: 5px;
  top: 400px;
  left: 80px;
}
#login_img .star span:nth-child(4) {
  width: 8px;
  height: 8px;
  top: 240px;
  left: 60px;
}
#login_img .star span:nth-child(5) {
  width: 4px;
  height: 4px;
  top: 20px;
  left: 200px;
}
#login_img .star span:nth-child(6) {
  width: 4px;
  height: 4px;
  top: 460px;
  left: 410px;
}
#login_img .star span:nth-child(7) {
  width: 6px;
  height: 6px;
  top: 250px;
  left: 350px;
}
#login_img .fly_star span {
  width: 90px;
  height: 3px;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0.67),
    rgba(255, 255, 255, 0)
  );
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0.67),
    rgba(255, 255, 255, 0)
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.67),
    rgba(255, 255, 255, 0)
  );
  transform: rotate(-45deg);
}
#login_img .fly_star span:nth-child(1) {
  top: 60px;
  left: 80px;
}
#login_img .fly_star span:nth-child(2) {
  top: 210px;
  left: 332px;
  opacity: 0.6;
}
#login_img p {
  text-align: center;
  color: #af4b55;
  font-weight: 600;
  margin-top: 300px;
  font-size: 25px;
}
#login_img p i {
  font-style: normal;
  margin-right: 45px;
}
#login_img p i:nth-last-child(1) {
  margin-right: 0;
}

/* 响应式 */
@media screen and (max-width: 1000px) {
  #login_img {
    display: none;
  }
  #login_wrap {
    width: 490px;
    margin-right: -245px;
  }
  #login {
    width: 100%;
  }
}
@media screen and (max-width: 560px) {
  #login_wrap {
    width: 330px;
    margin-right: -165px;
  }
  #login span {
    margin-left: -125px;
  }
  .form input {
    width: 250px;
  }
  .btn {
    width: 113px;
  }
}
@media screen and (max-width: 345px) {
  #login_wrap {
    width: 290px;
    margin-right: -145px;
  }
}
</style>
